import React, { useState, useEffect, useContext } from 'react';
import { LightboxContext } from "./layout";
import { motion } from "framer-motion";
import '../styles/lightbox.scss';

const Lightbox = () => {
  let lightboxSettings = useContext(LightboxContext);
  let [currentImageUrl, setCurrentImageUrl] = useState(lightboxSettings.currentImage.url);
  let [currentCaption, setCurrentCaption] = useState(lightboxSettings.currentImage.caption);
  let [currentVariant, setCurrentVariant] = useState(lightboxSettings.currentImage.variant);
  console.log(currentVariant);
  let [transitionState, setTransitionState] = useState("initial");
  let [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if(lightboxSettings.currentImage.url !== "") {
      setCurrentImageUrl(lightboxSettings.currentImage.url);
      setCurrentCaption(lightboxSettings.currentImage.caption);
      setCurrentVariant(lightboxSettings.currentImage.variant);
      setIsVisible(true);
      setTimeout(() => {
        setTransitionState("visible");
      }, 0)
    }
  }, [lightboxSettings.currentImage]);

  let bgVariants = {
    initial: {
      opacity: 0
    },
    visible: {
      opacity: 1
    }
  }

  let imgVariants = {
    initial: {
      opacity: 0,
      translateY: 10
    },
    visible: {
      opacity: 1,
      translateY: 0
    }
  }

  let btnVariants = {
    initial: {
      opacity: 0,
      translateY: 10
    },
    visible: {
      opacity: 1,
      translateY: 0
    }
  }

  let closeLightbox = (e) => {
    if(e) {
      if(e.target === e.currentTarget) {
        setTransitionState("initial");
        setTimeout(() => {
          setIsVisible(false);
        }, 300)
      }
    } else {
      setTransitionState("initial");
      setTimeout(() => {
        setIsVisible(false);
      }, 300)
    }
  };

  return (
    <div className="lightbox" style={{
      display: isVisible ? "flex" : "none"
    }}>
      <motion.figure onClick={ e => closeLightbox(e)} initial={transitionState} transition={{ duration: 0.2 }} variants={bgVariants} animate={transitionState} class="lightbox__item">
        <motion.picture className={`lightbox__picture lightbox__picture--${currentVariant}`} transition={{delay: 0.3, duration: 0.45}} initial={transitionState} variants={imgVariants} animate={transitionState}>
          <img alt={currentCaption} src={currentImageUrl}/>
        </motion.picture>
        <motion.figcaption transition={{delay: 0.5, duration: 0.2}} initial={transitionState} variants={imgVariants} animate={transitionState}>
          { currentCaption }
        </motion.figcaption>
      </motion.figure>
      <motion.button transition={{delay: 0.5, duration: 0.2}} initial={transitionState} variants={btnVariants} animate={transitionState} onClick={ () => { closeLightbox() }} className="lightbox__close">
        Close
      </motion.button>
    </div>
  );
};

export default Lightbox;