/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, createContext } from "react"
import PropTypes from "prop-types"
import Lightbox from "../components/Lightbox";
import Sidebar from "./sidebar";
import "./layout.scss";

export const LightboxContext = createContext({
  currentImage: {},
  setCurrentImage: () => {}
});

const Layout = ({ children }) => {

  let updateCurrentImage = ({ url, caption, variant }) => {
    updateImageSettings({
      ...imageSettings,
      currentImage: {
        url: url,
        caption: caption,
        variant: variant
      }
    })
  }

  let initialState = {
    currentImage: {
      url: "",
      caption: "",
      variant: ""
    },
    setCurrentImage: updateCurrentImage
  };

  let [imageSettings, updateImageSettings] = useState(initialState);

  return (
    <LightboxContext.Provider value={imageSettings}>
      <div class="main-wrap">
        <Sidebar/>
        <Lightbox/>
        <main>{children}</main>
        <footer>
          <p>© {new Date().getFullYear()} George Walker, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby.</a>
          </p>
          <p>
            Designed and built by <a href="https://www.twitter.com/iambillwill">@Iambillwill.</a>
          </p>
        </footer>
      </div>
    </LightboxContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
