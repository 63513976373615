/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import "../styles/sidebar.scss"
const Sidebar = () => {
  const data = useStaticQuery(graphql`
    query mainPageQuery {
      kenticoCloudItemPage {
        id
        elements {
          content {
            resolvedHtml
          }
          page_title {
            text
          }
        }
      }
      allKenticoCloudItemCollection {
        edges {
          node {
            elements {
              collection_name {
                value
              }
              url {
                value
              }
            }
          }
        }
      }
    }
  `)
  return (
    <aside class="sidebar">
      <div class="sidebar__text-wrap">
        <h1><Link to="/">{data.kenticoCloudItemPage.elements.page_title.text}</Link></h1>
        <div class="sidebar__rte rte" dangerouslySetInnerHTML={{__html: data.kenticoCloudItemPage.elements.content.resolvedHtml}}></div>
      </div>
      <div class="sidbear__nav-wrap">
        <ul>
          { data.allKenticoCloudItemCollection.edges.map(({node}) => {
            return <li><Link to={node.elements.url.value}>{node.elements.collection_name.value}</Link></li>
          })}
        </ul>
      </div>
    </aside>
  )
}

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Sidebar
